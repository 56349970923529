<template>
  <editor-pane class="lexicon-pane">
    <lexicon :words="words" />
    <tag-selector />
  </editor-pane>
</template>

<script>
import EditorPane from '@/components/EditorPane.vue'
import Lexicon from '@/components/Lexicon.vue'
import TagSelector from '@/components/TagSelector.vue'

export default {
  name: 'LexiconPane',
  components: { EditorPane, Lexicon, TagSelector },
  computed: {
    project () {
      return this.$store.state.editor.project
    },
    tag () {
      return this.$store.state.editor.tag
    },
    words () {
      if (this.tag === null || this.tag.language === null) return []
      const { year, language } = this.tag
      return this.project.generateLexicon(language, year + 1)
    },
  },
}
</script>

<style lang="scss">
.lexicon-pane {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{ $toolbar-height });
  overflow: none;

  .lexicon {
    flex-grow: 1;
  }

  .tag-selector {
    flex-grow: 0;
    flex-shrink: 0;
    border-top: 2px solid var(--c-border);
  }
}
</style>
