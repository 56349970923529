<template>
  <div class="tag-selector">
    <div class="top">
      <select name="language" @change="onSelectLanguage" ref="select" :disabled="langsArray.length === 0">
        <option v-if="langsArray.length === 0" selected>No langs defined</option>
        <option v-for="language in langsArray" :key="language.identifier" :value="language.identifier">{{ language.name }}</option>
      </select>
      <div class="selected">
        <output class="lang">{{ selectedMilestone?.lang?.name ?? 'No selection' }}</output> @
        <output class="year">{{ selectedMilestone?.year ?? 0 }}</output>
      </div>
    </div>
    <div class="slider">
      <input
        class="range"
        type="range"
        :min="min"
        :max="max"
        :value="selectedMilestone?.year ?? 0"
        @input="onInput"
        @change.stop="onInput"
        ref="slider"
        :disabled="milestones.length == 0"
      />
      <ul class="pips">
        <li v-for="pip in pips" :key="pip" class="pip" :style="`left: ${pip.percentage}%`">
          <button class="link" @click="setMilestone(pip)">{{ pip.year }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagSelector',
  data () {
    return {
      selectedMilestone: null,
      selectedLanguage: null,
    }
  },
  computed: {
    tag () {
      return this.$store.state.editor.tag
    },
    languages () {
      return this.$store.state.editor.project?.languages ?? new Map()
    },
    langsArray () {
      return Array.from(this.languages.values())
    },
    milestones () {
      if (this.selectedLanguage === null) return []
      const milestones = []
      let current = this.selectedLanguage
      while (current !== null) {
        milestones.push(
          ...Array.from(current.milestones.values())
            .map(year => {
              return {
                year: year,
                lang: current,
              }
            }))
        current = current.parent
      }
      return milestones.sort((a, b) => a.year - b.year)
    },
    min () {
      return Math.min(...this.milestones.map(milestone => milestone.year))
    },
    max () {
      return Math.max(...this.milestones.map(milestone => milestone.year))
    },
    pips () {
      const min = this.min
      const max = this.max
      return this.milestones.map(milestone => {
        return {
          year: milestone.year,
          lang: milestone.lang,
          percentage: ((milestone.year - min) / (max - min)) * 100,
        }
      })
    },
  },
  methods: {
    setMilestone (milestone, updateTag = true) {
      this.selectedMilestone = milestone
      this.$refs.slider.value = milestone.year
      this.$refs.select.value = this.selectedLanguage.identifier
      if (updateTag) {
        this.$store.commit('setTag', {
          year: milestone.year,
          language: this.selectedLanguage,
        })
      }
    },
    setLanguage (identifier, updateTag = true) {
      const language = this.languages.get(identifier)
      this.selectedLanguage = language
      const localMilestones = Array.from(language.milestones.values())
      const year = localMilestones[0]
      const milestone = this.getClosestMilestone(year)
      this.setMilestone(milestone, updateTag)
    },
    onInput (event) {
      const year = event.target.value
      const milestone = this.getClosestMilestone(year)
      if (milestone === this.selectedMilestone) return
      this.setMilestone(milestone)
    },
    onSelectLanguage (event) {
      this.setLanguage(event.target.value)
    },
    getClosestMilestone (year) {
      let closest
      let minDiff = Infinity
      for (const milestone of this.milestones) {
        const diff = Math.abs(year - milestone.year)
        if (diff < minDiff) {
          minDiff = diff
          closest = milestone
        }
      }
      return closest
    },
  },
  watch: {
    tag (after) {
      if (after.language === null) {
        this.selectedLanguage = null
      } else {
        this.setLanguage(after.language.identifier, false)
        const milestone = this.getClosestMilestone(after.year)
        this.setMilestone(milestone, false)
      }
    },
  },
  mounted () {
    const tag = this.$store.state.editor.tag
    const lang = tag?.language ?? this.langsArray[0]
    if (lang === undefined) return
    this.setLanguage(lang.identifier)
    const milestone = tag ? { year: tag.year, lang: tag.language } : this.milestones[0]
    this.setMilestone(milestone)
  },
}
</script>

<style lang="scss" scoped>
.tag-selector {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-items: center;
  padding: 1rem 0;
  background: var(--c-bg-shade);

  .top {
    position: relative;
    width: 100%;
    height: calc(2em + 2px);
    text-align: right;
    padding-right: 5%;

    @include md {
      main:not(.split) & {
        text-align: center;
        padding: 0;
      }
    }

    select {
      position: absolute;
      left: 5%;
    }

    .selected {
      font-size: 1.2rem;
      line-height: calc(2em + 2px);

      output {
        font-weight: 800;
        color: var(--c-highlight);
      }
    }
  }

  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding-bottom: 2rem;

    .range {
      width: 90%;
      z-index: 10;
      -webkit-appearance: none;

      --thumb-color: var(--c-highlight);
      --thumb-cursor: pointer;

      &:disabled {
        --thumb-color: var(--c-txt-secondary);
        --thumb-cursor: not-allowed;
      }

      @mixin track {
        height: 8px;
        width: 100%;
        background: var(--c-bg);
        border: 0;
        border-radius: none;
        cursor: pointer;
      }

      @mixin progress {
        @include track;
        background: var(--c-highlight);
      }

      @mixin thumb {
        margin-top: -4px;
        height: 16px;
        width: 16px;
        background: var(--thumb-color);
        border: 0;
        border-radius: 0;
        cursor: var(--thumb-cursor);
        -webkit-appearance: none;

        slider:not(:disabled) &:hover {
          filter: brightness(140%);
        }
      }

      &::-webkit-slider-runnable-track {
        @include track;
      }

      &::-moz-range-track {
        @include track;
      }

      &::-moz-range-progress {
        @include progress;
      }

      &::-webkit-slider-thumb {
        @include thumb;
      }

      &::-moz-range-thumb {
        @include thumb;
      }

      &:focus {
        &::-webkit-slider-thumb {
          outline: 2px solid var(--c-bg-shade);
          outline-offset: -4px;
        }

        &::-moz-range-thumb {
          @include thumb;
        }
      }
    }

    .pips {
      position: relative;
      list-style: none;
      width: calc(90% - 16px);
      pointer-events: none;

      .pip {
        position: absolute;
        transform: translateX(-50%);

        button {
          pointer-events: auto;
          font-weight: 600;
          text-decoration: none;
        }

        &::before {
          content: '';
          display: block;
          position: relative;
          top: -12px;
          width: 6px;
          height: 1rem;
          background: var(--c-bg);
          left: calc(50% - 3px);
        }
      }
    }
  }
}
</style>
