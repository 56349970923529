<template>
  <div class="lexicon">
    <div class="word-list" :class="{ halved: selected !== null }">
      <h2>Lexicon</h2>
      <ol class="words">
        <li v-for="word in words" :key="word.gloss" :data-ident="word.gloss">
          <button @click="setSelected(word.gloss)">
            <span class="gloss">{{ word.gloss }}</span>
            <span class="transcription">/{{ word?.render() }}/</span>
            <div class="definition" :class="{ empty: !word.definition }">{{ word.definition ?? '-'}}</div>
          </button>
        </li>
      </ol>
    </div>
    <div class="word-details" v-if="selected">
        <h3>{{ selected.gloss }}</h3>
        <section>
          <h4>Pronunciation</h4>
          <ul>
            <li><b>IPA:</b> /{{ selected.render() }}/</li>
            <li><b>Listen:</b> <button class="listen" disabled><font-awesome-icon icon="volume-up" /></button> <small>(Coming soon!)</small></li>
          </ul>
        </section>
        <section>
          <h4>Etymology</h4>
          <p style="color: var(--c-txt-secondary); font-style: italic;">This entry has no etymological information.</p>
        </section>
        <section>
          <h4>Meaning</h4>
          <ul>
            <li><b v-if="selected.pos">{{ selected.pos }}.</b> {{ selected.definition ?? '-' }}</li>
          </ul>
        </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lexicon',
  props: {
    words: Array,
  },
  data () {
    return {
      selectedGloss: null,
    }
  },
  computed: {
    selected () {
      return this.words.find(word => word.gloss === this.selectedGloss) ?? null
    },
  },
  methods: {
    setSelected (word) {
      this.selectedGloss = word
      document.querySelectorAll('.words > li').forEach(elem => {
        elem.classList.remove('selected')
      })
      document.querySelector(`[data-ident=${word}]`).classList.add('selected')
    },
  },
}
</script>

<style lang="scss">
.lexicon {
  position: relative;

  background: var(--c-bg);
  color: var(--c-txt-primary);

  display: flex;
  flex-direction: column;

  height: calc(100vh - 200px);

  @include md {
    main:not(.split) & {
      flex-direction: row;

      .word-list,
      .word-details {
        height: 100%;
        width: 50%;
      }

      .word-list {
        padding-top: 1rem;
        border-right: 2px solid var(--c-border);
        border-bottom: none;
      }
    }
  }

  .word-list,
  .word-details {
    flex-basis: 1;
    padding: 1rem;

    overflow-y: auto;
    min-height: 0;
    min-width: 0;

    height: 50%;
    width: 100%;
  }

  .word-list {
    grid-area: lexicon;
    border-bottom: 2px solid var(--c-border);
    height: 100%;
    padding-top: 7rem;

    @include md {
      padding-top: 1rem;
    }

    &.halved {
      height: 50%;
    }

    h2 {
      margin-bottom: 1rem;
    }

    .words {
      list-style: none;

      li, .header-row {
        background: var(--c-bg-shade);
        display: flex;

        div:nth-child(1) {
          width: 20%;
        }

        div:nth-child(2) {
          width: 20%;
        }

        div:nth-child(3) {
          width: 60%;
        }
      }

      li {
        button {
          width: 100%;
          padding: 0.5rem 1rem;
          border: none;
          text-align: left;
          border-radius: 0;

          span + span {
            margin-left: 1ch;
          }

          .gloss {
            font-weight: 800;
            color: var(--c-txt-emphasis);
          }

          .transcription {
            font-weight: 300;
          }

          .definition {
            font-weight: 400;
            font-style: italic;

            &.empty {
              color: var(--c-txt-secondary);
            }
          }

          &:focus {
            outline: 2px solid var(--c-bg-alt);
            outline-offset: -4px;

            &:hover {
              outline-color: var(--c-bg);
            }

            &::before {
              display: none;
            }
          }
        }

        &.selected {
          button {
            background: var(--c-bg-alt);
            color: var(--c-txt-alt);
            outline-color: var(--c-bg);
          }
        }

        & + li {
          margin-top: 0.5rem;
        }
      }

      .header-row {
        border-bottom: 2px solid var(--c-border);
        margin-bottom: 0.5rem;
        padding: 0.5rem 1rem;

        .header {
          font-weight: 900;
        }
      }
    }
  }

  .word-details {
    grid-area: details;

    h3 {
      font-size: 28px;

      &.tip {
        text-align: center;
        color: var(--c-txt-secondary);
        margin-block-start: 3rem;
      }
    }

    section {
      & + section {
        margin-block-start: 1rem;
      }

      h4 {
        font-size: 20px;
      }

      li {
        margin-left: 1.5rem;
      }
    }

    .listen {
      padding: 2px;
      border: none;
      border-radius: 50%;
      width: 1.7em;
      height: 1.7em;
    }
  }
}
</style>
